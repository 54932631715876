<template>
    <div id="contentwrap">
        <PageTitle />
        <div id="homes-sold" class="row">
            <div class="inner">
                <div class="flex-container">
                    <div></div>
                    <h2 class="title">Sold</h2>
                    <a href="https://www.zillow.com/profile/Meredithschlosser/#reviews" target="_blank">
                        <img class="zillow-star" src="/images/badge-zillow.png" alt="5 stars agent">
                    </a>
                </div>
            </div>
            <div v-if="!$store.getters.getLoaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <GmapMap
                v-if="$store.getters.getLoaded"
                id="map"
                :center="initalLocation"
                :zoom="mapZoom"
                map-type-id="terrain"
                :options="mapStyle"
                >
                <GmapInfoWindow
                    class="window"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    @closeclick="infoWinOpen=false"
                    v-if="currentMapDisplayProperty.Title !== ''">
                    <div class="infobox" ref="mapInfo">
                        <div class="left-panel">
                            <img :alt="`${currentMapDisplayProperty.Title}`" :src="hostUrl+currentMapDisplayProperty.DisplayImage.url">
                        </div>
                        <div class="right-panel">
                            <h3>{{currentMapDisplayProperty.Address}}</h3>
                            <h6>{{currentMapDisplayProperty.Price | priceFormat}}</h6>
                            <h5><router-link :to="`/property/${currentMapDisplayProperty.Slug}`">View</router-link></h5>
                        </div>
                    </div>
                </GmapInfoWindow>
                <GmapMarker
                        v-for="(p, i) in propertiesWithMap"
                        :key="i + '_marker'"
                        :position="{lat: parseFloat(p.Lat), lng: parseFloat(p.Lng)}"
                        :clickable="true"
                        :icon="markerIcon"
                        class="marker"
                        @click="toggleInfoWindow(p, i)">
                </GmapMarker>
            </GmapMap>
            <div v-if="$store.getters.getLoaded" class="dropdown-filters">
                <div class="filter-container">
                    <multiselect v-model="area" placeholder="FILTER BY AREA" :showLabels="false" :options="uniqueAreas" :searchable="false" :allow-empty="false" @select="optionSelect()">
                        <template :aria-labe="option" slot="singleLabel" slot-scope="{ option }"><strong>{{ option }}</strong></template>
                    </multiselect>
                </div>
                <div class="filter-container">
                    <multiselect v-model="priceFilter.value" track-by="label" label="label" placeholder="FILTER BY PRICE" :options="priceFilter.options" :searchable="false" :showLabels="false" :allow-empty="false" @select="optionSelect()">
                        <template :aria-labe="option.label"  slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                    </multiselect>
                </div>
            </div>
            <div v-if="!loaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <Properties v-if="$store.getters.getLoaded && loaded" v-bind:properties="filteredProperties" v-bind:fromHomepage="false" v-bind:ribbonText="false" />
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import Spinner from 'vue-simple-spinner'
import Properties from '../components/Properties.vue'
import Multiselect from 'vue-multiselect'

export default {
    name: "Sold",
    components: {
        PageTitle,
        Spinner,
        Properties,
        Multiselect
    },
    data(){
        return{
            hostUrl: 'https://strapi.uptowncreative.io',
            loaded: true,
            initalLocation: {
                lat: 34.0522,
                lng: -118.4437,
            },
            windowToggled: false,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            infoOptions: {
                //optional: offset infowindow so it visually sits nicely on top of our marker
                maxWidth: 200,
                pixelOffset: {
                    width: 0,
                    height: -45
                }
            },
            currentMapDisplayProperty: {
                Title: ''
            },
            markerIcon :"/images/mapmarker.png",
            mapZoom: 11,
            priceFilter: {
                value: { label: "FILTER BY PRICE", price: [0, 1000000000] },
                // true indicates listing, false indicates lease
                options:[
                    { label: "FILTER BY PRICE", price: [0, 1000000000] },
                    { label: "All", price: [0, 1000000000] },
                    { label: '$4M+', price: [4000000, 1000000000, false] },
                    { label: '$2M - $3.99M', price: [2000000, 4000000, false] },
                    { label: '$1M - $1.99M', price: [1000000, 2000000, false] },
                    { label: '<$1M', price: [0, 1000000, false] },
                    { label: 'Lease', price: [0, 1000000000, true] }
                ],
            },
            area: "FILTER BY AREA",
            mapStyle: {
                zoom: 11,
                disableDefaultUI: true,
                zoomControl: true,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    }
                ]
            }
        }
    },
    metaInfo: {
        title: 'Sold',
        titleTemplate: '%s by Meredith Schlosser',
        meta: [{
            name: 'description', 
            content: "Meredith Schlosser has sold homes in Los Angeles, Beverly Hills, Hollywood Hills, Brentwood, Santa Monica, Pacific Palisades, Encino, Sherman Oaks, Marina Del Rey, Tarzana, Beverlywood, Palms-Mar Vista, Beverly Center-Miracle Mile, and Westwood."
        },
        {
            name: 'author', 
            content: "Meredith Schlosser"
        },]
    },
    methods:{
        toggleInfoWindow: function(property, idx) {
            this.windowToggled = true;
            this.infoWindowPos = {lat: parseFloat(property.Lat), lng: parseFloat(property.Lng)}
            this.currentMapDisplayProperty = property
            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
        optionSelect: function() {
            this.loaded = false;
            window.setTimeout(() => {
                this.loaded = true
            }, 200)
            this.infoWinOpen = false;
        },
        handleClickOutside(event) {
            setTimeout(() => {
                const div = this.$refs.mapInfo;
                 if (div && !div.contains(event.target) && window.innerWidth < 600 && this.infoWinOpen && !this.windowToggled) {
                    this.infoWinOpen = false;
                    // Add your logic when clicking outside
                }
                this.windowToggled = false;
            }, 0)
        }
    },
    mounted() {
    // Listen for clicks on the whole document
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        // Clean up the event listener when the component is destroyed
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed:{
        propertiesWithMap: function () {
            let myProperties = this.$store.getters.properties.filter((property) => {
                return property.Status === 'sold';
            })
            myProperties = myProperties.filter(function (p) {
                return p.Lat && p.Lng;
            })
            if(this.priceFilter.value.price.length !== 2){
                myProperties = myProperties.filter((property) => {
                    if (this.priceFilter.value.label === 'Lease') {
                        return property.Lease;
                    }
                    return property.Price <= this.priceFilter.value.price[1] && property.Price > this.priceFilter.value.price[0]
                })
            }
            if(this.area !== "All" && this.area !== 'FILTER BY AREA'){
                myProperties = myProperties.filter((property) => {
                    if (!property.Area){
                        return false;
                    }
                    return property.Area.toUpperCase().trim() === this.area;
                })
            }
            return myProperties;

        },
        uniqueAreas: function(){
            let a = this.$store.getters.properties.map((p) => {
                let str = ''
                if(p.Area !== undefined && p.Area !== null){
                    str = p.Area.toUpperCase().trim();
                }
                return str;
            }).filter((a, i, l) => l.indexOf(a) === i && a !== '').sort();
            a.unshift("All");
            a.unshift("FILTER BY AREA");
            return a;
        },
        filteredProperties:function(){
            let myProperties = this.$store.getters.properties.filter((property) => {
                return property.Status === 'sold';
            })
            if(this.priceFilter.value.price.length !== 2){
                myProperties = myProperties.filter((property) => {
                    if (this.priceFilter.value.label === 'Lease') {
                        return property.Lease;
                    }
                    return property.Price <= this.priceFilter.value.price[1] && property.Price > this.priceFilter.value.price[0]
                })
            }
            if(this.area !== "All" && this.area !== 'FILTER BY AREA'){
                myProperties = myProperties.filter((property) => {
                    if (!property.Area){
                        return false;
                    }
                    return property.Area.toUpperCase().trim() === this.area;
                })
            }
            return myProperties;
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.flex-container{
    display:flex;
    justify-content: space-between;
    margin: 36px 20px;
    align-items: center;
	max-width: 1020px;
}


</style>

<style lang="scss">
.gm-style .gm-style-iw-tc{
    left: 100px;
}
.gm-style .gm-style-iw-tc::after{
    background: #ececec !important;
}
.window{
    margin-left: 100px;
}
.dropdown-filters{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.multiselect{
    width: 250px;
    text-align: left;
    font-family: "Oswald",Helvetica,Arial,sans-serif;
    cursor: pointer;
    border: 1px solid #636363;
    background: #FFFFFF;
    position: relative;
    border-radius: 0px;
    margin: 10px 20px;
}
.multiselect__tags{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    line-height: 33px;
    color: #444444;
}
.multiselect__single{
    strong{
        font-weight: 500;
    }
}
.multiselect__option--selected{
    font-weight: 500;
    font-family: "Oswald",Helvetica,Arial,sans-serif;
}
.multiselect__option{
    font-family: "Oswald",Helvetica,Arial,sans-serif;
    text-transform: uppercase;
}
.multiselect__option--highlight{
    background: #f0f0f0;
}
.multiselect__option--selected.multiselect__option--highlight{
    background: #D0D0D0;
}
.multiselect__option--selected.multiselect__option--highlight{
    &::before{
        color: #000;
    }
    &::after{
        color: #000;
    }
    &:hover{
        color: #000;
    }
}
.multiselect__option--highlight{
    &::before{
        color: #000;
    }
    &::after{
        color: #000;
    }
    &:hover{
        color: #000;
    }
}

.zillow-star{
    width: 120px;
}

@media (max-width: $mobile) { 
    .multiselect{
        width: 250px;
    }
}
</style>